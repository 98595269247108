
  import {mapGetters} from 'vuex'
  import wAxios from '@/plugins/w/axios'
  import { alertController } from '@ionic/vue'

  const api = {
    deleteProfile: () => wAxios.delete_auth('v1/auth/delete-profile')
  }

  export default {
        computed: {
      ...mapGetters('wAuth', ['user']),
    },

    data() {
      return {
        version: process.env.VUE_APP_VERSION,
      }
    },

    methods: {
      async deleteProfileConfirm() {
        await this.presentAlertConfirm()
      },

      async presentAlertConfirm() {
        const confirmAlert = await alertController
            .create({
              header: 'Confirm',
              message: 'Are you sure you want to permanently remove this account?',
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                },
                {
                  text: 'Okay',
                  handler: async () => {
                    let isDeleted = await api.deleteProfile()
                    if(isDeleted) {
                      await this.$store.dispatch('wAuth/logout')
                      this.$router.push('login')
                      window.location.reload()
                    }
                    else
                      alert('Error...')
                  },
                },
              ],
            })
        return confirmAlert.present()
      },
    }
  }
